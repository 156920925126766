import { type Factor } from "@slashid/slashid";

export function createSlashIDOptions(): [SlashIDOptions, null] | [null, Error] {
  const opts = window.SlashIDLogin;

  if (typeof opts === "undefined") {
    return [
      null,
      new Error(
        "SlashIDLogin options property is missing in the global `window` object."
      ),
    ];
  }

  if (!opts.orgID || typeof opts.orgID !== "string") {
    return [
      null,
      new Error(
        "Organization ID (orgID) property is missing in `window.SlashIDLogin` options."
      ),
    ];
  }

  if (
    !opts.externalCredentialsID ||
    typeof opts.externalCredentialsID !== "string"
  ) {
    return [
      null,
      new Error(
        "External Credentials ID (externalCredentialsID) property is missing in `window.SlashIDLogin` options."
      ),
    ];
  }

  if (!opts.baseApiURL || typeof opts.baseApiURL !== "string") {
    return [
      null,
      new Error(
        "Base API URL (baseApiURL) property is missing in `window.SlashIDLogin` options."
      ),
    ];
  }

  if (!opts.sdkURL || typeof opts.sdkURL !== "string") {
    return [
      null,
      new Error(
        "SDK URL (sdkURL) property is missing in `window.SlashIDLogin` options."
      ),
    ];
  }

  if (opts.formText.advanced) {
    try {
      JSON.parse(opts.formText.advanced);
    } catch {
      return [
        null,
        new Error("Text overrides (formText.advanced) is invalid JSON"),
      ];
    }
  }

  // safe defaults
  if (!opts.storeURL || typeof opts.storeURL !== "string") {
    opts.storeURL = window.location.origin;
  }

  return [opts, null];
}

export function mapFactors(options: SlashIDOptions): Factor[] {
  const factors = [];

  Object.entries(options.authFactors).forEach(([method, isEnabled]) => {
    if (isEnabled) {
      if (method === "password") {
        factors.push({
          method,
          // disable phone number handle type for passwords
          allowedHandleTypes: ["email_address"],
        });
      } else {
        factors.push({ method: method as Factor["method"] });
      }
    }
  });

  if (options.oidc.google.enabled) {
    factors.push({
      method: "oidc",
      options: {
        provider: "google",
        client_id: options.oidc.google.clientID,
        ux_mode: options.oidc.uxMode,
      },
    });
  }

  if (options.oidc.facebook.enabled) {
    factors.push({
      method: "oidc",
      options: {
        provider: "facebook",
        client_id: options.oidc.facebook.clientID,
        ux_mode: options.oidc.uxMode,
      },
    });
  }

  return factors as Factor[];
}
