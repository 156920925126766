import { Component, ErrorInfo, ReactNode } from "react";
import { handleError } from "../utils/errors";

interface Props {
  children?: ReactNode;
}

/**
 * ErrorBoundary is a component that catches errors in its children and sends them to Sentry.
 */
export class ErrorBoundary extends Component<Props> {
  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    handleError(error, errorInfo);
  }

  public render() {
    return this.props.children;
  }
}
