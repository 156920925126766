import { useEffect } from "react";
import { handleError } from "../utils/errors";

type Props = {
  error: Error;
};

export function OptionsError({ error }: Props) {
  useEffect(() => {
    if (
      !window.location.ancestorOrigins.contains("https://admin.shopify.com")
    ) {
      // log error only if it occurred outside of theme editor (Shopify Admin)
      handleError(error, {
        options: window.SlashIDLogin,
      });
    }
  }, []);

  return (
    <div
      style={{
        fontFamily: "inherit",
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        padding: 12,
        borderRadius: 16,
        background: "#FFEBEC",
        marginBottom: 24,
      }}
    >
      <h2>Invalid SlashID Login Widget configuration</h2>
      <h3>{error.message}</h3>
    </div>
  );
}
