import React from "react";
import ReactDOM from "react-dom/client";
import { init } from "@sentry/react";

import App, { APP_ROOT_ID } from "./components/App";
import { ErrorBoundary } from "./components/error-boundary";
import { OptionsError } from "./components/options-error";
import { createSlashIDOptions } from "./utils/options";
import "./styles/index.css";

// init sentry
init({
  dsn: "https://d187edf975258e8c2d993a5b57c0f25a@o4505317722619904.ingest.sentry.io/4506389383806976",
  integrations: function (integrations) {
    // do not attach global handlers to prevent noise, we capture exceptions manually
    return integrations.filter(function (integration) {
      return integration.name !== "GlobalHandlers";
    });
  },
});

if (import.meta.env.MODE === "development") {
  window.SlashIDLogin = {
    orgID: "00000000-0000-0000-1400-000000000000",
    externalCredentialsID: "<EXTERNAL_CRED>",
    baseApiURL: "https://api.sandbox.slashid.com",
    sdkURL: "https://cdn.sandbox.slashid.com/sdk.html",
    storeURL: "https://slashid-test-store.myshopify.com/",
    authFactors: {
      webauthn: true,
      email_link: true,
      otp_via_sms: true,
      otp_via_email: true,
      password: true,
    },
    oidc: {
      uxMode: "popup",
      google: {
        enabled: true,
        clientID: "google",
      },
      facebook: {
        enabled: true,
        clientID: "facebook",
      },
    },
    formStyles: {
      logoURL: "",
      primaryColor: "#121212",
    },
    formText: {
      title: "Welcome",
      subtitle: "Sign in to your account",
      button: "Sign in",
      error: "Something went wrong...",
      success: "You are now authenticated!",
      advanced: "",
    },
  };
}

const [opts, error] = createSlashIDOptions();

ReactDOM.createRoot(document.getElementById(APP_ROOT_ID) as HTMLElement).render(
  <React.StrictMode>
    {error ? (
      <OptionsError error={error} />
    ) : (
      <ErrorBoundary>
        <App options={opts} />
      </ErrorBoundary>
    )}
  </React.StrictMode>
);
