import type { User } from "@slashid/slashid";
import { useEffect, useLayoutEffect } from "react";
import {
  SlashIDProvider,
  ConfigurationProvider,
  Form,
  SlashIDLoaded,
  LoggedIn,
  useSlashID,
} from "@slashid/react";
import { ShopifySDK } from "@slashid/shopify-sdk";
import { mapFactors } from "../utils/options";
import { handleError } from "../utils/errors";

export const APP_ROOT_ID = "slashid-login-root";

type Props = {
  options: SlashIDOptions;
};

function isAuthenticatedWithDirectID(user: User | undefined) {
  return Boolean(
    user?.authentications?.some((auth) => auth.method === "direct_id")
  );
}

function parseAdvancedTextOverrides(overrides: string): Record<string, string> {
  if (!overrides || typeof overrides !== "string") {
    return {};
  }

  try {
    const parsedOverrides = JSON.parse(overrides);
    return parsedOverrides;
  } catch {
    return {};
  }
}

async function logInToShopify(user: User, options: SlashIDOptions) {
  const sdk = new ShopifySDK({
    baseApiURL: options.baseApiURL,
    storeURL: options.storeURL,
  });

  // read query params to optionally add extra fields to the token
  const urlSearchParams = new URLSearchParams(window.location.search);
  const return_to = urlSearchParams.get("return_to");

  const token = await sdk.getMultipassToken({
    slashIDToken: user.token,
    externalCredentialID: options.externalCredentialsID,
    ...(return_to && { multipassFields: { return_to } }),
  });

  sdk.navigateToShopify(token);
}

const DirectIDRedirect = ({ options }: Props) => {
  const { user } = useSlashID();

  useEffect(() => {
    if (user && isAuthenticatedWithDirectID(user)) {
      logInToShopify(user, options);
    }
  }, [user]);

  return null;
};

export default function App({ options }: Props) {
  useLayoutEffect(() => {
    const loginWidgetRoot = document.querySelector(`#${APP_ROOT_ID}`);

    if (loginWidgetRoot) {
      // @ts-expect-error TypeScript doesn't recognize the `styles` value, although it works
      loginWidgetRoot.style.setProperty(
        "--sid-color-primary",
        options?.formStyles.primaryColor
      );
      // @ts-expect-error as above
      loginWidgetRoot.style.setProperty(
        "--sid-color-primary-hover",
        options?.formStyles.primaryColor
      );
    }
  });

  if (!options) {
    return null;
  }

  const onSuccess = async (user: User) => {
    return logInToShopify(user, options);
  };

  const onError = (error: Error) => {
    handleError(error);
  };

  const factors = mapFactors(options);

  const advancedTextOverrides = parseAdvancedTextOverrides(
    options.formText.advanced
  );

  return (
    <SlashIDProvider
      oid={options.orgID}
      baseApiUrl={options.baseApiURL}
      themeProps={{ theme: "light" }}
      analyticsEnabled={options?.analyticsEnabled}
    >
      <ConfigurationProvider
        text={{
          "factor.webauthn": "Passkeys",
          "initial.title": options.formText.title,
          "initial.subtitle": options.formText.subtitle,
          "initial.submit": options.formText.button,
          "success.title": options.formText.success,
          "error.title": options.formText.error,
          ...advancedTextOverrides,
        }}
        logo={options.formStyles.logoURL}
        factors={factors}
        showBanner={false}
        storeLastHandle
      >
        <Form className="form" onSuccess={onSuccess} onError={onError} />
        <SlashIDLoaded>
          <LoggedIn>
            <DirectIDRedirect options={options} />
          </LoggedIn>
        </SlashIDLoaded>
      </ConfigurationProvider>
    </SlashIDProvider>
  );
}
