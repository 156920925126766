import { ErrorInfo } from "react";
import { captureException, withScope } from "@sentry/react";
import { Errors } from "@slashid/slashid";

type ErrorExtraInfo = Record<string, unknown> | ErrorInfo;

async function logError(error: unknown, context: ErrorExtraInfo = {}) {
  const slashIdError = await Errors.toSlashIDError(error);

  withScope((scope) => {
    scope.setExtras({
      ...context,
      ...slashIdError.context,
    });
    captureException(slashIdError);
  });
}

/**
 * Sync wrapper for async logError()
 *
 * Generates metadata automatically
 */
export function handleError(error: unknown, context: Record<string, any> = {}) {
  logError(error, context);
}